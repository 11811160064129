<template>
  <div class="anipang4 wrapper">
    <div id="intro"
      class="intro section">
      <div class="container">
        <br><br><br><br>
        <h2 class="section-title">
          <img :src="sections.intro.title" alt="대전 모드로 스릴 넘치는 배틀로얄 3매치, 애니팡4"/>
        </h2>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100 text-center">
            <img :src="sections.intro.phone" alt="phone">
          </div>
          <div class="section-contents md-layout-item md-size-50 text-center md-small-size-100 mx-auto">
            <div class="section-description">
              <h3 class="game-description">
                팸 멤버로 하트 걱정 없이!<br>
                때로는 혼자, 때로는 함께!<br>
                <br>
                두근두근 스릴 넘치는 퍼즐,
                애니팡4를 바로 만나보세요<br>
              </h3>
              <br><br><br>
              <div class="game-tag">
                <span>#20대1</span><br>
                <span>#정면승부</span><br>
                <span>#함께만나는_소셜스퀘어</span><br>
                <span>#애니팡친구들일상공개는_덤</span>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="down-links md-layout">
          <a href="https://ani4.onelink.me/k8vU?af_ios_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.sundaytoz.kakao.anipang4&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.sundaytoz.kakao.anipang4&af_xp=custom&pid=Brandpage&c=2212_AP4_KR_APS_BR_PMO_BRANDPAGE_SUB&af_dp=AFstzkakaoanipang4%3A%2F%2F" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
            <img :src="sections.intro.google_bt" alt="google">
          </a>
          <a href="https://ani4.onelink.me/k8vU?af_android_url=https%3A%2F%2Fapps.apple.com%2Fkr%2Fapp%2Fid1483015354&af_web_dp=https%3A%2F%2Fapps.apple.com%2Fkr%2Fapp%2Fid1483015354&af_xp=custom&pid=Brandpage&c=2212_AP4_KR_APS_BR_PMO_BRANDPAGE_SUB&af_dp=AFstzkakaoanipang4%3A%2F%2F" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
            <img :src="sections.intro.apple_bt" alt="apple">
          </a>
          <a href="https://ani4.onelink.me/k8vU?af_ios_url=https%3A%2F%2Fm.onestore.co.kr%2Fmobilepoc%2Fapps%2FappsDetail.omp%3FprodId%3D0000747180&af_android_url=https%3A%2F%2Fm.onestore.co.kr%2Fmobilepoc%2Fapps%2FappsDetail.omp%3FprodId%3D0000747180&af_web_dp=https%3A%2F%2Fm.onestore.co.kr%2Fmobilepoc%2Fapps%2FappsDetail.omp%3FprodId%3D0000747180&af_xp=custom&pid=Brandpage&c=2212_AP4_KR_APS_BR_PMO_BRANDPAGE_SUB&af_dp=AFstzkakaoanipang4%3A%2F%2F" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
            <img :src="sections.intro.onestore_bt" alt="onestore">
          </a>
        </div>
        <br><br><br>
      </div>
    </div>

  </div>
</template>

<script>

  export default {
    bodyClass: "anipang4",
    created() {},
    computed: {},
    data() {
      return {
        sections: {
          intro: {
            title: require("@/assets/img/pages/sub_page_a4_main_title.png"),
            phone: require("@/assets/img/pages/sub_page_a4_phone.png"),
            apple_bt: require("@/assets/img/pages/apple_bt.png"),
            google_bt: require("@/assets/img/pages/google_bt.png"),
            onestore_bt: require("@/assets/img/pages/onestore_bt.png"),
          },
        }
      };
    }
  };
</script>
<style lang="scss" scoped>
  .anipang4 {
    .intro {
      background: rgb(234,255,232);
      background: linear-gradient(135deg, rgba(164,243,242,1) 0%, rgba(234,255,232,1) 100%);

      .container {
        .section-title {
          text-align: center;
        }
        .section-contents {
          padding-left: 30px !important;
          padding-right: 30px !important;
          display: flex;
          flex-wrap: wrap;
          align-content: center;

          .section-description {
            -webkit-font-smoothing: antialiased;
            text-align: left;

            .game-title{
              color: #3c736d;
            }
            .game-description{
              color: #3c736d;
            }
            .game-tag {
              span {
                color: #3c736d;
                border-radius: 100px;
                background-color: white;
                border: 1px solid #3c736d;
                padding: 10px 15px;
                margin: 5px 5px 5px 0;
                display: inline-block;
                font-size: 21px;
                box-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
              }
            }
          }
        }

        .down-links {
          margin: 0 auto;
          max-width: 900px;

          .md-layout-item {
            text-align: center;
          }
        }
      }
    }
  }
</style>